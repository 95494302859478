// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";

import { useSelector } from "react-redux";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { login_Session } = useSelector((state) => state.auth);

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return (
          <NavGroup login_Session={login_Session} key={item.id} item={item} />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  const navItemsClient = menuItem.client_items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
  
  const navItemsIndividual = menuItem.Individual_items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      {login_Session?.isSuperadmin
        ? navItems
        : login_Session?.isCompanyadmin == true
        ? navItemsClient
        : navItemsIndividual}
    </>
  );
};

export default MenuList;
